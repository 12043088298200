@use '../../styles/yr' as *;

.hourly-weather-table {
  margin-top: 1rem;
  margin-bottom: 2em;
  position: relative;
  z-index: 0;
}

// Hide wind description on smaller screens
@include mq-lte($mq-640) {
  .hourly-weather-table__wind-description {
    display: none;
  }
}

// OVERRIDE: Make cloud cells grey
.fluid-table__cell.hourly-weather-table__cloud {
  background-color: var(--color-background-muted-gray);
}

.hourly-weather-table__weather {
  display: block;
}

// OVERRIDE(scb): Increase the size of the weather symbol
.hourly-weather-table__weather > .weather-symbol {
  height: rem(38);
  width: rem(38);
}

// We add a minimum width to the `time` column
// This is to ensure that it has the same width throughout all tables in details page
.hourly-weather-table__time {
  min-width: rem(42px);
}
