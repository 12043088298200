@use '../../styles/yr' as *;

.celestial-events {
  gap: size(1);
}

.celestial-events {
  display: flex;
}

.celestial-events__event {
  display: flex;
}

.celestial-events__icon {
  color: var(--color-fill-accent-cool-orange);
}

.celestial-events__event-text {
  padding-right: size(0.5);
  padding-left: size(0.5);
}
