@use '../../styles/yr' as *;

.graph-line--dashed {
  .graph-line__line,
  .graph-line__path {
    stroke-dasharray: 3, 5;
    stroke-linecap: square;
  }
}

.graph-line__point--outline {
  fill: var(--color-stroke-base);
}

.graph-line__line--gap {
  color: var(--color-graph-no-data-stroke);
  stroke-dasharray: 4, 4;
}

.graph-line__line--outline {
  stroke: var(--color-stroke-base);
}

.graph-line__path {
  stroke-linecap: round;
}

.graph-line__path--outline {
  stroke: var(--color-stroke-base);
}
