@use '../../styles/yr' as *;

$width: size(1);
$corner-height: size(4);

.scroll-shadow {
  position: relative;
  width: $width;
  height: 100%;
  opacity: 0;
  background-size: 100% calc(100% - #{2 * $corner-height});
  background-position: 0 $corner-height;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: opacity $ui-transition;
}

.scroll-shadow[data-visible='true'] {
  opacity: 0.3;
}

.scroll-shadow:before,
.scroll-shadow:after {
  display: block;
  content: '';
  position: absolute;
  height: $corner-height;
  width: 100%;
}

.scroll-shadow:before {
  top: 0;
}

.scroll-shadow:after {
  bottom: 0;
}

.scroll-shadow--left {
  background-image: linear-gradient(to right, var(--color-fill-base), $theme-background-primary-transparent);

  &:before {
    background-image: radial-gradient(at 0% 100%, var(--color-fill-base), $theme-background-primary-transparent $width);
  }

  &:after {
    background-image: radial-gradient(at 0% 0%, var(--color-fill-base), $theme-background-primary-transparent $width);
  }
}

.scroll-shadow--right {
  background-image: linear-gradient(to left, var(--color-fill-base), $theme-background-primary-transparent);

  &:before {
    background-image: radial-gradient(
      at 100% 100%,
      var(--color-fill-base),
      $theme-background-primary-transparent $width
    );
  }

  &:after {
    background-image: radial-gradient(at 100% 0%, var(--color-fill-base), $theme-background-primary-transparent $width);
  }
}
