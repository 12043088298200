@use '../../styles/yr' as *;

$line-color: var(--color-fill-subtle);

$text-line-height: rem(24px);
$circle-width: rem(11px);
$circle-border-width: rem(1px);
$circle-center-width: rem(7px);
$arrow-size: rem(7px);
$dotted-line-width: rem(1px);

.warning-timeline {
  position: relative;
  z-index: 0;
}

.warning-timeline__item {
  position: relative;
  padding-left: $circle-width + size(1);

  &:first-child:before {
    background-image: linear-gradient(0deg, $line-color 60%, transparent 25%);
    background-size: $dotted-line-width 5px;
    content: '';
    display: block;
    position: absolute;
    width: $dotted-line-width;

    // Horizontally center the dotted line perfectly with the center of the circle.
    // We need to account for the width of the dotted line as well.
    left: $circle-width * 0.5 - $dotted-line-width * 0.5;

    // Start the dotted line just below this item's circle
    top: $circle-width + (($text-line-height - $circle-width) * 0.5);

    // End the dotted line just before the circle of the next item
    bottom: -(($text-line-height - $circle-width) * 0.5);
  }
}

.warning-timeline__item--with-open-end {
  // When we have a single open ended timeline item with an arrow at the bottom
  // we don't want to render the arrow just below the item circle, so we give
  // the item a minimum height equal to if the text were to wrap to two lines.
  // This ensures the item is tall enough that a little of the dotted line will
  // be visible.
  min-height: $text-line-height * 2;

  &:first-child:before {
    // There is no next item with a circle so we end the dotted line
    // at the tip of the arrow.
    bottom: 0;
  }
}

.warning-timeline__circle {
  border-radius: 50%;
  box-shadow: inset 0 0 0 $circle-border-width $line-color;
  height: $circle-width;
  left: 0;
  position: absolute;
  width: $circle-width;

  // Vertically align the circle with the first line of the text
  top: $text-line-height * 0.5 - $circle-width * 0.5;

  &:after {
    background-color: $line-color;
    border-radius: 50%;
    content: '';
    display: block;
    height: $circle-center-width;
    left: ($circle-width - $circle-center-width) * 0.5;
    position: absolute;
    top: ($circle-width - $circle-center-width) * 0.5;
    width: $circle-center-width;
  }
}

.warning-timeline__ending-arrow {
  border-bottom: 1px solid $line-color;
  border-right: 1px solid $line-color;
  bottom: 0;
  height: $arrow-size;
  position: absolute;
  transform: rotate(45deg);
  width: $arrow-size;

  // Horizontally center the dotted line perfectly with the center of the circle
  left: ($circle-width - $arrow-size) * 0.5;
}
