@use '../../styles/yr' as *;

.sun-graph__phase {
  &[data-type='night'] {
    fill: var(--color-graph-sun-fill-night);
  }
  &[data-type='astronomical twilight'] {
    fill: var(--color-graph-sun-fill-astronomical);
  }
  &[data-type='nautical twilight'] {
    fill: var(--color-graph-sun-fill-nautical);
  }
  &[data-type='civil twilight'] {
    fill: var(--color-graph-sun-fill-civil);
  }
}

// OVERRIDE(tl): We want to set the color of the horizon line so we are overriding the stroke color of '.graph-grid-new__line'
.sun-graph {
  .graph-grid-new__line {
    stroke: var(--color-graph-sun-horizon-line);
  }
}
