@use '../../styles/yr' as *;

.sun-card {
  display: flex;
  @include mq-lte($mq-675) {
    min-height: unset;
  }
  .sun-graph__line {
    color: var(--color-graph-sun-solar-path);
  }
  .sun-card__card {
    width: 100%;

    transition: background-color 0.2s;
  }
  &[data-sun-phase='night'] .sun-card__card {
    background-color: var(--color-graph-sun-background-night);
  }
  &[data-sun-phase='astronomical twilight'] .sun-card__card {
    background-color: var(--color-graph-sun-background-astronomical);
  }
  &[data-sun-phase='nautical twilight'] .sun-card__card {
    background-color: var(--color-graph-sun-background-nautical);
  }
  &[data-sun-phase='civil twilight'] .sun-card__card {
    transition: background-color 1s;
    background-color: var(--color-graph-sun-background-civil);
  }
  &[data-sun-phase='daylight'] .sun-card__card {
    transition: background-color 1s;
    background-color: var(--color-graph-sun-background-daylight);
    color: var(--color-text-black);
  }
}

.sun-card__card-content {
  display: flex;
  flex-direction: column;
  @include mq-gte($mq-675) {
    padding-top: size(2.5);
  }
}

.sun-card__elevation-text {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.sun-card__card-details {
  margin-top: 2rem;
}

.sun-card__card-sun-events-list {
  padding-bottom: size(2);
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.sun-card__card-sun-events-list-text {
  white-space: nowrap;
}

.sun-card__card-details-text {
  white-space: unset;
}

.sun-card__icon {
  margin-right: size(0.5);
  color: var(--color-fill-accent-cool-orange);
}

@include mq-gte($mq-675) {
  .sun-card__card-content {
    flex-direction: row;
  }

  .sun-card__card-details {
    margin: 0 0 0 size(5.5);
  }

  .sun-card__card-details-text {
    white-space: nowrap;
  }
}
