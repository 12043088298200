@use '../../styles/yr' as *;

$low-background: var(--color-warnings-green-fg-fill);
$low-border: 1px solid var(--color-warnings-green-fg-border);
$moderate-background: var(--color-warnings-yellow-fg-fill);
$moderate-border: 1px solid var(--color-warnings-yellow-fg-border);
$severe-background: var(--color-warnings-orange-fg-fill);
$severe-border: 1px solid var(--color-warnings-orange-fg-border);
$extreme-background: var(--color-warnings-red-fill);
$extreme-border: 1px solid var(--color-warnings-red-border);
$extreme-check-mark: var(--color-fill-white);
$check-mark: var(--color-fill-black);

.warning-severity {
  display: flex;
  flex-direction: column;
}

.warning-severity__box-wrapper {
  margin-bottom: 0.1rem;
}

.warning-severity__box {
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  top: 0.2rem;

  &[data-severity='Minor'] {
    background-color: $low-background;
    border: $low-border;
    color: $check-mark;
  }

  &[data-severity='Moderate'] {
    background-color: $moderate-background;
    border: $moderate-border;
    color: $check-mark;
  }

  &[data-severity='Severe'] {
    background-color: $severe-background;
    border: $severe-border;
    color: $check-mark;
  }

  &[data-severity='Extreme'],
  &[data-severity='Ultra'] {
    background-color: $extreme-background;
    border: $extreme-border;
    color: $extreme-check-mark;
  }
}

.warning-severity__description {
  margin-left: 0.5rem;

  &[data-disabled='true'] {
    color: var(--color-text-disabled);
  }
}

.warning-severity__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
