@use '../../styles/yr' as *;

.warning-image-dialog {
  text-align: center;
}

.warning-image-dialog__image {
  max-width: 60rem;
  width: 100%;
}
