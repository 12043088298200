@use '../../styles/yr' as *;

.hourly-weather-dialog__celestial_events_container {
  display: flex;
  justify-content: center;
}

.hourly-weather-dialog__celestial_events-link-sun-card {
  margin-left: size(2);
}
