@use '../../styles/yr' as *;

$sort-icon-active-color: var(--color-fill-active);
$sort-button-hover-color: var(--color-fill-active);

.fluid-table {
  // Create new stacking context to contain z-indexes
  position: relative;
  z-index: 0;
}

.fluid-table__caption {
  /* 
    Ideally we should use the nrk-sr class or mixin to visually hide screen reader accessible items.
    But Chrome + Voiceover has a bug which skips reading the last row in a table - if the caption is
    hidden with the standard nrk-sr css (specifically position: absolute; seems to be the problem.) 
    The following margin-top: -1px; hack prevents that bug from happening while having no visual impact.
  */
  margin-top: -1px;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
}

.fluid-table__scroll-shadow-left,
.fluid-table__scroll-shadow-right {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3; // Render in front of the sticky table cells
  pointer-events: none;
}

.fluid-table__scroll-shadow-left {
  left: 0;
}

.fluid-table__scroll-shadow-right {
  right: 0;
}

.fluid-table__scroll-container {
  overflow-x: auto;
  overflow-y: hidden; // Prevent a vertical scrollbar in IE
  overscroll-behavior-x: contain; // Prevent history navigating when scrolling quickly to the start or end
  -webkit-overflow-scrolling: touch; // Enable momentum scrolling in iOS
}

.fluid-table__table {
  border-collapse: collapse;
  width: 100%;
}

/**
 * A narrow table, as determined by how many columns it contains,
 * won't take up the entire width of its parent container.
 * The table will only be as wide as determined by its columns' intrinsic widths.
 */
.fluid-table__table--narrow {
  width: auto;
}

.fluid-table__sort-icon {
  display: inline-block;

  .fluid-table__cell[aria-sort='ascending'] &,
  .fluid-table__cell[aria-sort='descending'] & {
    color: $sort-icon-active-color;
  }

  .fluid-table__cell[aria-sort='ascending'] & {
    transform: rotate(180deg);
  }
}

.fluid-table__row--link {
  // Highlight the entire row when hovered by adding a background color to each cell
  @include hover {
    .fluid-table__cell {
      background-color: var(--color-background-muted-core-blue);
      cursor: pointer;
    }
  }

  .fluid-table__cell-content--link {
    text-decoration: none;
  }

  &:focus-within {
    // When tabbing to a link within the row we want to highlight the entire row
    .fluid-table__cell {
      background-color: var(--color-background-muted-core-blue);
    }

    // When `:focus-within` is supported we can safely remove the outline from focused cell content links
    .fluid-table__cell-content--link:focus {
      outline: 0;
    }
  }
}

.fluid-table__cell {
  background-color: var(--color-background-base);
  position: relative; // Make it possible to add a z index value to table cells
  white-space: nowrap;

  &[aria-sort] {
    cursor: pointer;
  }
}

.fluid-table__sort-button {
  width: 100%;

  &:hover {
    color: $sort-button-hover-color;
  }
}

.fluid-table__cell-content {
  align-items: center;
  display: flex;
  height: size(5) + rem(1px); // Add 1px to account for the border
  justify-content: center;
  padding: 0 size(0.5);

  border-bottom: $divider-solid;
  // Prevent rotated wind arrow from making the table scrollable when it shouldn't be
  overflow: hidden;

  .fluid-table__cell--heading & {
    height: size(4) + rem(1px); // Add 1px to account for the border
  }

  // Add more padding between each cell on larger screens but don't add more padding
  // to the left of the first cell or to the right of the last cell.
  @include mq-gt($mq-640) {
    padding: 0 rem(12px);

    .fluid-table__cell:first-child & {
      padding-left: rem(6px);
    }

    .fluid-table__cell:last-child & {
      padding-right: rem(6px);
    }
  }
}

.fluid-table__cell--align-left .fluid-table__cell-content {
  justify-content: flex-start;
}

.fluid-table__cell--align-right .fluid-table__cell-content {
  justify-content: flex-end;
}

.fluid-table__cell--heading {
  transition: 200ms opacity;
  z-index: 1; // Render in front of the other cells in the same column
}

.fluid-table__cell--sticky {
  left: 0;
  position: sticky;
  z-index: 1; // Render in front of the other cells in the same row
}

.fluid-table__cell--heading.fluid-table__cell--sticky {
  z-index: 2; // Render in front of the other sticky cells in the same column
}
