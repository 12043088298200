@use '../../styles/yr' as *;

.min-max-precipitation {
  display: inline-block;
}

.min-max-precipitation--hidden {
  visibility: hidden;
}

.min-max-precipitation__dash {
  color: var(--color-text-weather-precipitation);
}

.min-max-precipitation__dash--hidden {
  visibility: hidden;
}
