@use '../../styles/yr' as *;

.warning-heading {
  line-height: rem(20px);
  margin: 0 2.8em 0 3.3rem;
  pointer-events: none;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.warning-heading__title-wrapper {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
}

.warning-heading__title {
  display: inline-block;
  margin-right: size(1);
}

.warning-heading__subtitle {
  display: block;
}

.warning-heading__label {
  border-radius: rem(4px);
  display: inline-block;
  padding: rem(1px) rem(3px);

  background-color: var(--color-background-subtle-core-blue);
}
