@use '../../styles/yr' as *;

.graph-shell-new {
  position: relative;
  width: 100%;

  // Add an outline around the entire graph shell when the `.graph-shell-new__aria-labels`
  // element is focused (which is the only element within the graph shell that can receive focus.
  // We need to add the focus style to an absolutely positioned pseudo-element
  // because if we add it to the `.graph-shell-new` element itself the content within
  // would partially overlap the outline.
  &:focus-within {
    &:after {
      @include focus-style;

      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }
}

.graph-shell-new__aria-labels {
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.graph-shell-new__grid {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  position: relative;
  width: 100%;

  // Create a new stacking context to contain z indexes
  // within this element.
  z-index: 0;

  .graph-shell-new[data-is-scrollable='true'] & {
    overflow-x: auto;

    // Make room for the scrollbar below the graph
    padding-bottom: size(2);
  }
}

// We need to use a separate element for the background of the y axis instead of just adding a background color
// to `.graph-shell-new__y-axis`. The first and list items of the y axis are often partially rendered outside
// the y axis itself, which in the case of the meteogram could lead to the bottom number in the temperature
// y axis being cut in half because the y axis of the empty middle row would be rendered in front of it.
// We can't use a higher z index on the y axis items to solve this because unfortunately `position: sticky`
// creates a new stacking context.
.graph-shell-new__y-axis-background {
  background-color: var(--color-background-base);
  padding: 0 size(0.5);
  position: sticky;

  // Render the y axis backgrounds in front of `.graph-shell-new__graph-content`
  z-index: 1;

  &[data-position='left'] {
    left: 0;
  }

  &[data-position='right'] {
    right: 0;
  }

  &[data-has-min-width='true'] {
    // If we need to show multiple GraphShellNew with different graphs we need to set min-width on the left y-axis
    // to align the graphs correctly, min-width of 35px is wide enough for a temperature of -99°.
    min-width: rem(35px);
  }
}

.graph-shell-new__scroll-shadow {
  pointer-events: none;
  position: absolute;
  top: size(1);
  bottom: size(1);

  .graph-shell-new__y-axis-background[data-position='left'] & {
    left: 100%;
  }

  .graph-shell-new__y-axis-background[data-position='right'] & {
    right: 100%;
  }
}

.graph-shell-new__y-axis {
  display: flex;
  flex-direction: column;
  padding: 0 size(0.5);
  position: sticky;
  white-space: nowrap;
  z-index: 1;

  &[data-position='left'] {
    text-align: right;
    left: 0;
  }

  &[data-position='right'] {
    text-align: left;
    right: 0;
  }

  &[data-has-min-width='true'] {
    // If we need to show multiple GraphShellNew with different graphs we need to set min-width on the left y-axis
    // to align the graphs correctly, min-width of 35px is wide enough for a temperature of -99°.
    min-width: rem(35px);
  }
}

.graph-shell-new__y-axis-tick {
  height: 0;
  position: relative;
  z-index: 2;
}

.graph-shell-new__y-axis-label {
  display: block;
  transform: translateY(-50%);
}

.graph-shell-new__y-axis-icon {
  display: inline-block;
  transform: translateY(-50%);
}

.graph-shell-new__graph-content {
  // Add just enough padding to prevent the first and last vertical lines
  // of the GraphGrid from rendering behind the y axis columns.
  padding: 0 rem(1px);
}

.graph-shell-new__y-axis[data-hide-on-desktop='true'],
.graph-shell-new__graph-content[data-hide-on-desktop='true'] {
  @include mq-gte($mq-990) {
    display: none;
  }
}
