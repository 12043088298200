@use '../../styles/yr' as *;

// TODO(as): The design tokens repo supports adding rgba colors to it, so this should be defined as a design token
$text-background: rgba(var(--color-background-base), 0.5);

.graph-grid-new {
  position: relative;
  &[data-is-draggable='true'] {
    touch-action: pinch-zoom;
  }
}

.graph-grid-new__text {
  position: absolute;
  white-space: nowrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: $text-background;
}

.graph-grid-new__grid {
  display: block;
  width: 100%;
}

.graph-grid-new__line {
  stroke-width: rem(1px);
  stroke: var(--color-graph-axis);
}

.graph-grid-new__line--dotted {
  stroke-dasharray: 4, 3;
  stroke-width: rem(2px);
  stroke: var(--color-graph-axis-accent);
}

.graph-grid-new__line--dotted-thin {
  stroke-dasharray: 4, 3;
  stroke-width: rem(1px);
  stroke: var(--color-graph-axis-accent);
}

.graph-grid-new__line--bold {
  stroke-width: rem(1px);
  stroke: var(--color-graph-axis-accent);
}

.graph-grid-new__column-highlight {
  fill: var(--color-graph-hover);
}

.graph-grid-new__hover-image {
  transform-box: fill-box;
}

.graph-grid__circle-highlight {
  fill: currentColor;
}
